import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://predictionist.in"

export const getUserDetails = createAsyncThunk(
  "userData/fetchUser",
  async (authData, thunkAPI) => {
    // console.log( "**************" ,authData)    
    try {
        const req = await axios.post(`${API_URL}/api/home.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            // console.log( "data   ",req.data)
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const UserContestDetails = createAsyncThunk(
  "userData/userDetails",
  async (authData, thunkAPI) => {
    //  console.log(authData)
    try {
        const req = await axios.post(`${API_URL}/api/contest.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
             console.log(req.data)
             return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);

export const JoinContest = createAsyncThunk(
  "userData/reSendOtp",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/joined_contest.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);


export const MatchRank = createAsyncThunk(
  "userData/rank_team",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/match_ranking.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};



const UserData = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(UserContestDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserContestDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UserContestDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(JoinContest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(JoinContest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(JoinContest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(MatchRank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(MatchRank.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(MatchRank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});

export const userReducer = UserData.reducer 
export const UserSelector = (state) => state.userReducer;
