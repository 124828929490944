import axios from 'axios'
import React from 'react'
import { FaTrophy } from 'react-icons/fa'

function ShowRankPage({ team_points, user }) {
  const sortedTeams = [...team_points].sort((a, b) => b.total_point - a.total_point)
  
  let currentRank = 1
  let rankCount = 1

  const rankedTeams = sortedTeams.map((team, index) => {
    if (index > 0 && sortedTeams[index - 1].total_point !== team.total_point) {
      currentRank = rankCount
    }
    rankCount++
    return { ...team, rank: currentRank }
  })
  
  const loggedInUserTeam = rankedTeams.find((team) => team.name === user.name)
  const otherTeams = rankedTeams.filter((team) => team.name !== user.name)
  const finalTeams = loggedInUserTeam ? [loggedInUserTeam, ...otherTeams] : otherTeams

  const handleViewMyTeam =async(team)=>{
    const data ={
        join_code : team?.join_code,
        userteam_code : team?.userteam_code 
    }  
     try {
         const req = await axios.post("https://predictionist.in/api/team_point.php", data,{
            headers: {
                "Content-Type": "application/json",
            },
         })
         console.log(req.data)
     } catch (error) {
         console.log(error.message)
     }
  } 


  return (
    <div className="p-4 max-w-7xl mx-auto font-[poppins] mt-[-20px] mb-6">
      {/* Table Header */}
      <div className="flex items-center font-bold rounded-lg p-2 bg-indigo-100 py-4 text-sm z-30  md:text-base">
        <div className="w-1/2 md:w-1/3 ">Player Name</div>
        <div className="w-1/4 text-center">Points</div>
        <div className="w-1/4 text-right">Rank</div>
      </div>

      <ul className="space-y-1">
        {finalTeams?.map((team) => (
          <li key={team.join_code} onClick={() => user.name === team.name && handleViewMyTeam(team)}  className={`flex items-center h-auto  p-2 ${user.name === team.name ? "bg-green-400 cursor-pointer hover:bg-green-500" : ""} bg-white ${team.rank === 1 ? "border-l-8 border-[yellow]" : ""} ${team.rank === 2 ? "border-l-8 border-[#2dd5eba3]" : ""} ${team.rank === 3 ? "border-l-8 border-[#26d72fa3]" : ""} shadow-md rounded-lg`}>
            {/* Image and Name */}
            <div className="relative flex items-center gap-2 w-1/2 md:w-1/3">
              <img
                src={`https://via.placeholder.com/64?text=Image`}
                alt="Team"
                className="w-10 h-10 object-cover rounded-full"
              />
              <span className="font-semibold flex flex-wrap gap-2 md:text-[14px] text-[10px]">
                {team.name} {user.name === team.name && "(You)"}
                <span className="text-green-400 font-semibold md:text-[14px] text-[10px]">
               {team.status}{team.win === "0.00" ? "" : team.win}
              </span>
              </span>
            </div>
            {/* Points */}
            <div className="w-1/4 text-center">
              <p className="md:text-[14px] text-[10px] font-bold">{team.total_point}</p>
            </div>

            {/* Rank */}
            <div className="w-1/4 text-right md:text-[14px] text-[10px] font-bold">
              <FaTrophy className="text-yellow-500 inline-block mr-1" />
              <span>{team.rank}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ShowRankPage
