import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';


const PersonalInformation = () => {
  const [formData, setFormData] = useState({
    email: '',
    mobile_number : '',
    user_name: '',
    age: '',
    house_name: '',
    street : '',
    district: '',
    state: '',
    pincode: '',
    photo: '' 
});
  
  const [errors, setErrors] = useState({});
  const userEmail =  JSON.parse(sessionStorage.getItem("email"))
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
    

  
  useEffect(() => {
    const email = sessionStorage.getItem("email");
    if (email) {
      setFormData({ ...formData, email: JSON.parse(email) });
    } else {
      navigate('/login');
    }
  }, []);
  
  console.log( "user email is ",userEmail);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile_number ') {
        if (!/^\d*$/.test(value)) {
          return;
        }
      }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
      }
    });
    return newErrors;
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      // Log the file size
      console.log('Original file size (bytes):', file.size);
      
      // Check file type
      const validTypes = ['image/jpeg', 'image/jpg'];
      if (!validTypes.includes(file.type)) {
        alert('Please upload a JPG or JPEG image.');
        return;
      }
      
      // Resize image to 150x150 pixels
      Resizer.imageFileResizer(
        file,
        150,
        150,
        'JPEG',
        100,
        0,
        (uri) => {
          // Remove the data URL prefix
          const base64String = uri.replace(/^data:image\/jpeg;base64,/, '');
          
          setFormData({
            ...formData,
            photo: base64String
          });
        },
        'base64'
    );
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Submit form logic
       console.log("form data is " , formData)
      try {
          const req = await axios.post("https://predictionist.in/api/personal_process.php", formData, {
             headers: {
                "Content-Type": "application/json",
              },

          })
          if(req.data){
            console.log(req.data)
            
            //  sessionStorage.removeItem("email")
            //  navigate("/")
          }else{
             alert("please try again")
          }
       } catch (error) {
            console.log("error in register",error.message);
       }
    }
  };

//   text-purple-700
  return (
    <div className="flex justify-center items-center py-6 min-h-screen bg-gradient-to-br from-purple-400 to-blue-600">
      <div className="bg-[#ffffffb8] p-8 rounded-lg shadow-lg w-[90%] font-[poppins] md:w-[80%] ">
      <div className="logo h-[6rem] text-center w-[100%]">
          <img src="/LogoBlack.PNG" alt="Predictionist Kart Logo" className="h-[100%] w-[100%] object-contain" />
    </div>
        <h1 className="text-3xl font-bold text-center mb-6  text-purple-600">Personal Details</h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email */}
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              disabled
              id='email'
              className={`w-full p-3 rounded-lg border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>

          {/* mobile_number  (user can't change) */}
          <div>
          <label htmlFor="mobile_number ">Mobile Number  <span className='text-[9px] text-red-600 '>(can not change after register)</span></label>
            <div className='flex gap-2 flex-row'>
            <input
              value="+91"
              disabled
              className="w-[10%] min-w-[60px] p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
            <input
              type="text"
              name="mobile_number"
              placeholder="Mobile Number "
              value={formData.mobile_number }
              onChange={handleChange}
              maxLength={10}
              minLength={10}
              id='mobile_number '
              className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
            </div>
          </div>

          {/* Name */}
          <div>
          <label htmlFor="user_name">Name</label>
            <input
              type="text"
              name="user_name"
              placeholder="Name"
              value={formData.user_name}
              onChange={handleChange}
              id='user_name'
              className={`w-full p-3 rounded-lg border ${errors.name ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
          </div>

          {/* Age */}
          <div>
          <label htmlFor="age">Age</label>
            <input
              type="number"
              name="age"
              placeholder="Age"
              value={formData.age}
              onChange={handleChange}
              id='age'
              maxLength={2}
              minLength={2}
              className={`w-full p-3 rounded-lg border ${errors.age ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
            />
            {errors.age && <p className="text-red-500 text-sm mt-1">{errors.age}</p>}
          </div>
          <div>
            <label htmlFor="photo">Upload Photo</label>
            <input
              name='photo'
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={fileInputRef}
              id='photo'
              className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
            {formData.photo && <img src={formData.photo} alt="Uploaded preview" className='rounded-lg' style={{ width: '80px', height: '80px' }} />}
          </div>
          {/* Address Section */}
          <div className="mt-4">
            <label className="block mb-2 text-[13px] text-gray-600">
              There are login gifts. Please provide the correct address for delivering the gift when you earn.
              <span className='text-green-600 underline'>You can change it later</span>.
            </label>

            {/* House Number */}
            <div className="mb-2">
            <label htmlFor="house_name">House Name</label>
              <input
                type="text"
                name="house_name"
                placeholder="House Name or House Number"
                value={formData.house_name}
                onChange={handleChange}
                id='house_name'
                className={`w-full p-3 rounded-lg border ${errors.houseNumber ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              {errors.houseNumber && <p className="text-red-500 text-sm mt-1">{errors.houseNumber}</p>}
            </div>

            {/* Street */}
            <div className="mb-2">
            <label htmlFor="street">Street</label>
              <input
                type="text"
                name="street"
                placeholder="Street"
                value={formData.street}
                onChange={handleChange}
                id='street'
                className={`w-full p-3 rounded-lg border ${errors.street ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              {errors.street && <p className="text-red-500 text-sm mt-1">{errors.street}</p>}
            </div>

            {/* District */}
            <div className="mb-2">
            <label htmlFor="district">District</label>
              <input
                type="text"
                name="district"
                placeholder="District"
                value={formData.district}
                onChange={handleChange}
                id='district'
                className={`w-full p-3 rounded-lg border ${errors.district ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              {errors.district && <p className="text-red-500 text-sm mt-1">{errors.district}</p>}
            </div>

            {/* State */}
            <div className="mb-2">
            <label htmlFor="state">State</label>
              <input
                type="text"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
                id='state'
                className={`w-full p-3 rounded-lg border ${errors.state ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
            </div>

            {/* Pincode */}
            <div className="mb-2">
            <label htmlFor="pincode">Pincode</label>
              <input
                type="text"
                name="pincode"
                placeholder="Pincode"
                value={formData.pincode}
                onChange={handleChange}
                id='pincode'
                className={`w-full p-3 rounded-lg border ${errors.pincode ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              {errors.pincode && <p className="text-red-500 text-sm mt-1">{errors.pincode}</p>}
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-purple-600 text-white py-3 rounded-lg font-semibold hover:bg-purple-700 transition"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PersonalInformation;
