import axios from 'axios';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function ViewContestDetails() {
  const location = useLocation();
  const { contest_details = {}, requested_teams = [] } = location.state || {};
  console.log(contest_details)
  const userCode = sessionStorage.getItem("user_code");
  
  
  const handleRequest = async (content)=>{
    const data = {
        user_code : userCode,
        contest_code: contest_details?.contest_code,
        userteam_code :content?.team?.userteam_code,
        action: content?.type
      } 
    
      try {
         const req = await axios.post("https://predictionist.in/api/condition_approval.php" , data,{
          headers :{
            "Content-Type": "application/json",
           }
         })
          console.log(req.data) 
         } catch (error) {
           console.log(error.message) 
      }
  }

  
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Flex container for Contest Details and Requested Teams */}
      <div className="flex flex-col justify-center items-center gap-6">

        {/* Contest Details Box */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-3/4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Contest Details</h2>
          <div className="space-y-4">
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Admin:</p>
              <p className="text-gray-800">{contest_details?.admin || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Condition:</p>
              <p className="text-gray-800">{contest_details?.condition || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Condition Link:</p>
              <NavLink to={contest_details?.condition_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                {contest_details?.condition_link || 'N/A'}
              </NavLink>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Contest Code:</p>
              <p className="text-gray-800">{contest_details?.contest_code || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Description:</p>
              <p className="text-gray-800">{contest_details?.desc || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Free Join:</p>
              <p className="text-gray-800">{contest_details?.free_join || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Match Code:</p>
              <p className="text-gray-800">{contest_details?.match_code || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Contest Name:</p>
              <p className="text-gray-800">{contest_details?.name || 'N/A'}</p>
            </div>

            {/* Prize Details */}
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="flex justify-between">
                  <p className="font-semibold text-gray-600">{`Prize ${index + 1}:`}</p>
                  <p className="text-gray-800">
                    {contest_details?.[`prize${index + 1}`] ? contest_details[`prize${index + 1}`] : 'N/A'}
                  </p>
                </div>
              ))}
            </div>

            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Prize Pool:</p>
              <p className="text-gray-800">{contest_details?.prize_pool || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Total Spots:</p>
              <p className="text-gray-800">{contest_details?.total_spot || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Status:</p>
              <p className="text-gray-800">{contest_details?.status || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Type:</p>
              <p className="text-gray-800">{contest_details?.type || 'N/A'}</p>
            </div>
          </div>
        </div>

        {/* Requested Teams Box */}
        <div className="bg-white shadow-lg h-auto rounded-lg p-6 w-full md:w-3/4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Requested Teams</h2>
          <div className="space-y-4">
            {requested_teams.length > 0 ? (
              requested_teams.map((team, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm h-full">
                  <div className="flex justify-between">
                    <p className="font-semibold text-gray-600">User Team Code:</p>
                    <p className="text-gray-800">{team?.userteam_code || 'No data available'}</p>
                  </div>
                  
                  <div className="flex justify-between">
                    <p className="font-semibold text-gray-600">Condition Status:</p>
                    <p className="text-gray-800">{team?.condition_status || 'No data available'}</p>
                  </div>
                  <div className="flex justify-between flex-col">
                    <p className="font-semibold text-gray-600">Condition Proofs:</p>
                    <ul className="list-disc list-inside text-gray-800 h-[100px] w-[100%] flex justify-around gap-4">
                     <img src={`https://predictionist.in//${team.first_upload}`} alt="" className='md:h-[200px] md:w-[150px] h-[140px] w-[90px] p-2 rounded-lg'/>  
                     <img src={`https://predictionist.in//${team.second_upload}`} alt="" className='md:h-[200px] md:w-[150px]  h-[140px] w-[90px] p-2 rounded-lg'/>  
                    </ul>
                  </div>
                  <div className='mt-[130px] flex center justify-center items-center gap-10'>
                      <button onClick={()=>handleRequest({type : "Approved" , team})} className='text-white py-2 px-4 bg-green-600 rounded-lg font-semibold hover:bg-green-700 transition-all duration-200' >Accept</button>
                      <button onClick={()=>handleRequest({type : "Rejected" , team})} className='text-white py-2 px-4 bg-red-600 rounded-lg font-semibold hover:bg-red-700 transition-all duration-200' >Reject</button>
                    </div> 
                </div>
              ))
            ) : (
              <p className="text-gray-600">No teams requested yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewContestDetails;
