// import React, { useEffect, useState } from 'react';

// function RemainingTime({time}) {
//   const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

//   // Fetch data from localStorage and parse it
//   const data = JSON.parse(localStorage.getItem('NewData'));
//   const { upcoming_matches } = data || {};
//   const firstTimestamp = upcoming_matches?.[time]?.starting_time;
  
//   const calculateTimeLeft = () => {
//     if (!firstTimestamp) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

//     const sessionDate = new Date(firstTimestamp.replace(' ', 'T')); // Convert to ISO format
//     const now = new Date();
//     const difference = sessionDate - now;

//     if (difference <= 0) {
//       return { days: 0, hours: 0, minutes: 0, seconds: 0 };
//     }

//     const seconds = Math.floor((difference / 1000) % 60);
//     const minutes = Math.floor((difference / (1000 * 60)) % 60);
//     const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
//     const days = Math.floor(difference / (1000 * 60 * 60 * 24));

//     return { days, hours, minutes, seconds };
//   };

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [firstTimestamp]);

//   return (
//     <div className='flex gap-2'>
//       <span>{timeLeft.days}d</span>
//       <span>{timeLeft.hours}h</span>
//       <span>{timeLeft.minutes}m</span>
//       <span>{timeLeft.seconds}s</span>
//     </div>
//   );
// }

// export default RemainingTime;



import React, { useEffect, useState } from 'react';

function RemainingTime({ time }) {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // Retrieve and parse data from localStorage
  const storedData = localStorage.getItem('NewData');
  const data = storedData ? JSON.parse(storedData) : {};
  const { upcoming_matches } = data;
  const firstTimestamp = upcoming_matches?.[time]?.starting_time;

  const calculateTimeLeft = () => {
    if (!firstTimestamp) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const sessionDate = new Date(firstTimestamp.replace(' ', 'T')); // Convert to ISO format
    const now = new Date();
    const difference = sessionDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [firstTimestamp]);

  return (
    <div className='flex gap-2'>
      <span>{timeLeft.days}d</span>
      <span>{timeLeft.hours}h</span>
      <span>{timeLeft.minutes}m</span>
      <span>{timeLeft.seconds}s</span>
    </div>
  );
}

export default RemainingTime;
