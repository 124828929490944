import React from 'react'
import Home from './Home/home'

function Hero() {
  return (
    <div className=''>
      <Home/>       
  </div>
  )
}

export default Hero