import React from 'react'
import { NavLink } from 'react-router-dom'
import style from "../Error/error.module.scss"
function ErrorPage() {
  return (
    <div className={style.errorPage}>
         <div className={style.mid}>
            <h1>404</h1>
            <h2>OOPS! PAGE NOT FOUND</h2>
            <h3><strong>Sorry, the page you're looking for dosesn't exist. Please Return to <a className='underline hover:text-blue-500 transition-all duration-250' href="/">Home Page</a></strong></h3>
            <div>
                <button><NavLink className={`${style.nav}`} to="/">RETURN HOME</NavLink></button>
            </div>
         </div>
    </div>
  )
}

export default ErrorPage