import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://predictionist.in"

export const TeamMaker = createAsyncThunk(
  "teamMaker/team_maker",
  async (authData, thunkAPI) => {
    // console.log( "**************" ,authData)    
    try {
        const req = await axios.post(`${API_URL}/api/team_maker.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        // console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const EditTeam = createAsyncThunk(
  "teamMaker/edit_team",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/team_edit.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const JoiningTeam = createAsyncThunk(
  "teamMaker/joining_team",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/joining_team.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const MatchRank = createAsyncThunk(
  "teamMaker/rank_team",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/match_ranking.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const TeamUpdate = createAsyncThunk(
  "teamMaker/update_team",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/updating_userteam.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(req)
        if (req.data) {
            //  console.log(req.data)
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);


export const JoinContest2 = createAsyncThunk(
  "userData/reSendOtp",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/joined_contest.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);



const initialState = {
  data: null,
  loading: false,
  error: null,
};



const teamMaker = createSlice({
  name: "teamMaker",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TeamMaker.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(TeamMaker.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(TeamMaker.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(EditTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(EditTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(JoiningTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(JoiningTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(JoiningTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(MatchRank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(MatchRank.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(MatchRank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(JoinContest2.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(JoinContest2.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(JoinContest2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});

export const TeamReducer = teamMaker.reducer 
export const TeamSelector = (state) => state.TeamReducer;
