import React, { useEffect, useState } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { HiMenuAlt2 } from "react-icons/hi";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import style from "./nav.module.scss";
import axios from "axios";

function Navbar() {
  const [showAside, setShowAside] = useState(false);
  const userCode = sessionStorage.getItem("user_code");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [userDetails , setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true)

  const handleShowAside = () => {
    setShowAside(!showAside);
  };


  useEffect(() => {
    if (userCode) {
      fetchUserDetails();
    } else {
      navigate("/login");
    }
  }, [userCode]);

  useEffect(() => {
    setTimeout(() => {
      if (!userCode === userData?.user?.user_code) {
        navigate("/login");
        sessionStorage.removeItem("user_code");
      }
    }, 6000);
  }, []);


  
  const fetchUserDetails = async () => {
    setLoading(true)
    const data = { user_code: userCode };
    try {
      const req = await axios.post(
        "https://predictionist.in/api/home.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (req.data) {
        setUserData(req.data);
        localStorage.setItem("NewData", JSON.stringify(req.data));
        setLoading(false)  
    }
    } catch (error) {
      console.log("Error in fetching data:", error.message);
    }
  };
  
  useEffect(() => {
    setLoading(true)
    const UserDetails = async () => {
      try {
        const data = {
          user_code: userCode,
        };
        const req = await axios.post(
          "https://predictionist.in/api/user.php",
          data
        );
        // console.log("user data is ", req.data);
        if (req.data) {
          setUserDetails(req.data?.user_details);
          setLoading(false)
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    UserDetails();
  }, [userCode]);

  if(loading){
     return ""
  }
 
 
 const handleWalletPage = async ()=>{
  try {
     const req = await axios.post("https://predictionist.in/api/wallet.php", {user_code :userCode}, {
       headers: {
         "Content-Type": "application/json",
        },
        
      })
      navigate(`/wallet/${userCode}` , {state: req.data})
    } catch (error) {
       console.log(error.message)
       alert("please try again")
    }
 }
    
  return (
    <>
      <header className="relative">
        <nav className="nav fixed font-[poppins] h-[70px] w-[100%] flex justify-between items-center px-2 bg-[white] shadow-lg z-50">
          <div className="h-[100%] w-[40%] md:w-auto">
            <NavLink to={"/"}>
              <picture>
                <img
                  src="/LogoBlack.PNG"
                  alt="Logo"
                  className="h-[100%] w-auto object-contain"
                />
              </picture>
            </NavLink>
          </div>
          <div className="Wallet flex items-center justify-between gap-6 ">
            <div className="links h-[100%] hidden md:flex justify-around items-center w-auto gap-6">
              <button
                className={`${style.cursor_pointer} ${style.hover_border_animation} hover:text-[#14141e] transition-all duration-300 ease-in-out font-[600px]`}
                onClick={()=>navigate(`/profile`,{state : userDetails})} 
              >
                Profile
              </button>
              <NavLink
                className={`${style.cursor_pointer} ${style.hover_border_animation} hover:text-[#14141e] transition-all duration-300 ease-in-out font-[600px]`}
                to={"/playGuide"}
              >
                How to Play
              </NavLink>
              <NavLink
                className={`${style.cursor_pointer} ${style.hover_border_animation} hover:text-[#14141e] transition-all duration-300 ease-in-out font-[600px]`}
                to={"/earning"}
              >
                Earn Free Entry
              </NavLink>
              <NavLink
                className={`${style.cursor_pointer} ${style.hover_border_animation} hover:text-[#14141e] transition-all duration-300 ease-in-out font-[600px]`}
                to={"/about"}
              >
                About Us
              </NavLink>
            </div>
            <div className="flex gap-2">
              <div className="flex items-center gap-2">
                <IoWalletOutline className="font-extrabold text-[20px]" />
                <span className="md:text-[16px] text-[14px]"> ₹ {userDetails?.amount} </span>
              </div>
              <div onClick={handleWalletPage} className="profileImage cursor-pointer md:h-[40px] md:w-[40px] h-[30px] w-[30px] rounded-full overflow-hidden border-2 border-white">
                <img
                  src= {`https://predictionist.in/uploads/profile.png` || "/dummyUser.png"} alt="user image"
                  className="h-[100%] w-[100%] object-cover"
                />
              </div>
            </div>
            <div onClick={handleShowAside} className="menu md:hidden visible">
              <HiMenuAlt2 className="text-black md:text-[30px] text-[24px] cursor-pointer" />
            </div>
          </div>
        </nav>

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 left-0 w-full h-full bg-white z-40 transform ${
            showAside ? "translate-y-0" : "-translate-y-full"
          } transition-transform duration-500 ease-in-out md:hidden`}
        >
          <div className="flex px-[40px] flex-col mt-[76px] h-full">
            <NavLink
              onClick={handleShowAside}
              className={`${style.cursor_pointer} ${style.hover_border_animation} text-black hover:text-[#14141e] text-xl transition-all duration-300 ease-in-out mb-6`}
              to={"/profile"}
            >
              Profile
            </NavLink>
            <NavLink
              onClick={handleShowAside}
              className={`${style.cursor_pointer} ${style.hover_border_animation} text-black hover:text-[#14141e] text-xl transition-all duration-300 ease-in-out mb-6`}
              to={"/playGuide"}
            >
              How to Play
            </NavLink>
            <NavLink
              onClick={handleShowAside}
              className={`${style.cursor_pointer} ${style.hover_border_animation} text-black hover:text-[#14141e] text-xl transition-all duration-300 ease-in-out mb-6`}
              to={"/earning"}
            >
              Earn Free Entry
            </NavLink>
            <NavLink
              onClick={handleShowAside}
              className={`${style.cursor_pointer} ${style.hover_border_animation} text-black hover:text-[#14141e] text-xl transition-all duration-300 ease-in-out mb-6`}
              to={"/about"}
            >
              About Us
            </NavLink>
            <h2 className="h-[90px] max-w-[100%] flex justify-center">
              <picture>
                <img
                  src="/LogoBlack.PNG"
                  alt="Logo"
                  className="h-[100%] w-auto max-w-[300px] object-contain"
                />
              </picture>
            </h2>
          </div>
        </div>
      </header>
      <br />
      <br />
      <br />
      <Outlet />
    </>
  );
}

export default Navbar;
