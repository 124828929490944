import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ChangeEmail, ReSendOtp, SendEmail, verifyOtp } from '../../Reducers/Verification/verify';


const VerifyMobile = () => {
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    otp: '',
  });
  const [otpSent, setOtpSent] = useState(false);
  const [showResendOptions, setShowResendOptions] = useState(false);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  const storedUserCode = sessionStorage.getItem('user_code');
  const dispatch = useDispatch();

  useEffect(() => {
    if (storedUserCode) {
      navigate('/');
    }
  }, [navigate, storedUserCode]);

  useEffect(() => {
    let interval;
    if (!showResendOptions && otpSent) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setShowResendOptions(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showResendOptions, otpSent]);

  const validateStep = () => {
    const { email } = formData;
    let valid = true;
    const newErrors = { email: '' };

    if (!email.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;
    try {
      const data = {
        email: formData.email,
        action: 'send',
      };

      const resultAction = await dispatch(SendEmail(data));

      if (SendEmail.fulfilled.match(resultAction) && resultAction.payload) {
        setOtpSent(true);
        setMessage('OTP sent successfully!');
        setShowResendOptions(false);
        setTimer(60);
      } else {
        setMessage(resultAction.error?.message || 'Unexpected response');
      }
    } catch (error) {
      console.error('Error during API calls:', error);
      setMessage('An error occurred while processing your request');
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;

    try {
      const data = {
        email: formData.email,
        otp: formData.otp,
      };
       console.log( "data is :",data)
      const resultAction = await dispatch(verifyOtp(data));
       console.log(  "data verify : ",resultAction)
      if (verifyOtp.fulfilled.match(resultAction)) {
        if (resultAction.payload?.error) {
          if (resultAction.payload.error === "User not found. Please add personal details.") {
            setMessage(resultAction.payload.error);
            setFormData({
              ...formData,
              otp :''
            })
            sessionStorage.setItem('email', JSON.stringify(formData.email))
            sessionStorage.setItem('codes', (formData.user_code))
            // console.log(resultAction.payload)
           
            navigate("/personal")
             
          } else {
            setMessage(resultAction.payload.error);
            setFormData({
              ...formData,
              otp :''
            })
          }
        } else if (resultAction.payload?.user_code) {
          setMessage("OTP verified successfully");
          console.log("***** email present", resultAction.payload);
          const cookies = new Cookies();
          const accessToken = btoa(JSON.stringify(resultAction.payload));
          cookies.set('accessToken', accessToken, {
            path: '/',
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30), // 30 days
            sameSite: 'strict',
            secure: true,
          });
  
          sessionStorage.setItem('user_code', resultAction.payload.user_code);
          sessionStorage.setItem('user', JSON.stringify(resultAction.payload));
  
          setTimeout(() => {
             console.log("data ",resultAction.payload )
            // navigate('/');
          }, 1000);
        }
      } else {
        setMessage('Invalid OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setMessage('An error occurred while verifying OTP');
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;

    try {
      const data = {
        email: formData.email,
        action: 'resend',
      };
      const response = await dispatch(ReSendOtp(data));

      if (ReSendOtp.fulfilled.match(response) && response.payload) {
        setOtpSent(true);
        setMessage('OTP sent successfully!');
        setShowResendOptions(false);
        setTimer(60);
      } else {
        setMessage(response.error?.message || 'Please try again');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('An error occurred while sending OTP');
    }
  };

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;

    try {
      const data = {
        email: formData.email,
        action: 'change',
      };
      const response = await dispatch(ChangeEmail(data));

      if (ChangeEmail.fulfilled.match(response) && response.payload) {
        setOtpSent(false);
        setMessage('');
        setShowResendOptions(false);
        setTimer(60);
        setErrors({ email: '', otp: '' });
        setFormData({ email: '', otp: '' });
      } else {
        setMessage(response.error?.message || 'Please try again');
      }
    } catch (error) {
      console.error('Error changing email:', error);
      setMessage('An error occurred while changing the email');
    }
  };
  

  return (
    <div className="relative min-h-screen font-[poppins] flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-500">
      <div className="relative flex flex-col bg-[#ffffffb8] backdrop-blur-[5px] py-4 px-4 rounded-lg shadow-lg md:w-[40%] max-w-[600px] w-[80vw] h-full justify-around  z-20">
        <div className="logo h-[6rem] text-center w-[100%]">
          <img src="/LogoBlack.PNG" alt="Predictionist Kart Logo" className="h-[100%] w-[100%] object-contain" />
        </div>
        <div>
          <h1 className="md:text-3xl text-[20px] font-bold text-center mb-0 text-purple-700">
            Email Verification
          </h1>
          <p className="text-center text-gray-500 mb-2 mt-0 p-6 md:text-[20px] text-[13px]">
            {otpSent ? "OTP has already been sent. Please check your email" : "Enter a valid email, we will send otp for verification"}
          </p>
          <form onSubmit={otpSent ? handleOtpSubmit : handleSubmit}>
            {!otpSent && (
              <div className="mb-4 relative flex flex-col justify-center">
                <input
                  type="email"
                  className={`border p-2 w-full rounded mb-4 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Enter your Email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="relative mb-4 mt-[-15px]">
                  {errors.email && (
                    <span className="text-red-500 absolute top-0 left-0 text-[9px]">
                      {errors.email}
                    </span>
                  )}
                  {message && (
                    <p className="text-center text-red-500 text-[9px]">{message}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600"
                >
                  Send OTP
                </button>
              </div>
            )}

            {otpSent && (
              <>
                <div className="mb-4 relative flex flex-col justify-center">
                  <input
                    type="text"
                    className={`border p-2 w-full rounded mb-4 ${errors.otp ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="Enter OTP"
                    id="otp"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                  />
                  <div className="relative mb-4 mt-[-15px]">
                    {errors.otp && (
                      <span className="text-red-500 absolute top-0 left-0 text-sm">
                        {errors.otp}
                      </span>
                    )}
                    {message && (
                      <p className="text-center text-red-500">{message}</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600"
                  >
                    Verify OTP
                  </button>
                </div>
                <div className="text-center">
                  {showResendOptions && (
                    <button
                      onClick={handleResendOtp}
                      className="text-purple-500 underline"
                    >
                      Resend OTP
                    </button>
                  )}
                </div>
              </>
            )}
          </form>
          {otpSent && (
            <div className="w-[100%] flex justify-center gap-4 mt-4">
              {showResendOptions && (
                <button
                  onClick={handleChangeEmail}
                  className="bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300"
                >
                  Change Email
                </button>
              )}
              {!showResendOptions && (
                <p className="text-gray-500">
                  Resend OTP in {timer} seconds
                </p>
              )}
            </div>
          )}
        </div>
        <div className="text-center mt-4 text-gray-500 text-sm md:text-base">
        <p className="w-[100%] md:text-[13px] text-[12px]">
        If you have any trouble logging in,{' '}
        <NavLink
          className="text-blue-500 underline"
          to={'https://api.whatsapp.com/send/?phone=917907041646&text&type=phone_number&app_absent=0'}
        >
          Please connect us
        </NavLink>{' '}
        </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyMobile;
