import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ShowRankPage from "./ShowRankPage";
import PrizePoolPage from "./PrizePoolPage";
import PlayersStatus from "./playersStatus";

function Ranking() {
  const [showPage, setShowPage] = useState("ranking");
  const { userId } = useParams();
  const location = useLocation();
  const rankingData = location.state || {};
  
  const {
    team_points,
    player_points,
    contest_details,
    match_details,
    user_details,
  } = rankingData;
  const { score, teams, update_status } = match_details;
    

  return (
    <div className="bg-indigo-50 flex flex-col items-center min-h-[88vh]">
      <div className="teams flex items-center mt-10 mb-4 justify-center max-w-lg mx-auto">
        {/* Team 1 */}
        <div className="flex flex-col items-center">
          <img
            src={`https://predictionist.in/${teams[0].logo}`}
            alt={teams[0].team_name}
            className="md:w-16 md:h-16 h-12 w-12 rounded-full object-cover"
          />
          <p className="mt-2 text-lg font-semibold text-gray-700">
            {teams[0].teamName}
          </p>
        </div>

        {/* VS Symbol */}
        <div className="mx-8 text-3xl font-bold text-red-600">
          <img
            src="/vs1.png"
            alt="vs"
            className="md:h-10 md:w-10 w-8  h-8 object-cover"
          />
        </div>

        {/* Team 2 */}
        <div className="flex flex-col items-center">
          <img
            src={`https://predictionist.in/${teams[1].logo}`}
            alt={teams[1].team_name}
            className="md:w-16 md:h-16 h-12 w-12 rounded-full object-cover"
          />
          <p className="mt-2 text-lg font-semibold text-gray-700">
            {teams[1].teamName}
          </p>
        </div>
      </div>
      <div className="btn w-[100%] gap-2 py-4 flex justify-around items-center px-10 h-[50px]">
        <button
          onClick={() => setShowPage("ranking")}
          className="py-2 md:px-4 px-2 md:text-[15px] text-[10px]  max-w-[130px]  bg-indigo-400 text-white font-[poppins] rounded-lg hover:bg-indigo-600 transition-all duration-300 ease-in-out"
        >
          Ranking
        </button>
        <button
          onClick={() => setShowPage("prizePool")}
          className="py-2 md:px-4 px-2 md:text-[15px] text-[10px]  max-w-[130px]  bg-indigo-400 text-white font-[poppins] rounded-lg hover:bg-indigo-600 transition-all duration-300 ease-in-out"
        >
          Prize pool
        </button>
        <button
          onClick={() => setShowPage("playersStatus")}
          className="py-2 md:px-4 px-2 md:text-[15px] text-[10px]  max-w-[130px]  bg-indigo-400 text-white font-[poppins] rounded-lg hover:bg-indigo-600 transition-all duration-300 ease-in-out"
        >
          Player Status
        </button>
      </div>

      <div className="showPages mt-8 h-full w-[100%]">
        {showPage === "ranking" && (
          <ShowRankPage team_points={team_points} user={user_details} />
        )}
        {showPage === "prizePool" && <PrizePoolPage ContestDetails = {contest_details}/>}
        {showPage === "playersStatus" && <PlayersStatus  playerPoints={player_points} user={user_details}/>}
      </div>
    </div>
  );
}

export default Ranking;
