import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './Verification/verify';
import { userReducer } from './UserDetails/userDetails';


export const store = configureStore({
  reducer: {
    authReducer,
    userReducer    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
    


export default store;

