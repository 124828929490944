import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import RemainingTime from "./RemainingTime";
import axios from "axios";
import { getUserDetails, UserContestDetails, JoinContest,   } from "../../../Reducers/UserDetails/userDetails";
import { useDispatch } from "react-redux";


function Home() {
  const [match, setMatch] = useState("live");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const dispatch =  useDispatch();

  const userCode = sessionStorage.getItem("user_code");
  
  useEffect(() => {
    if (userCode) {
      fetchUserDetails();
    } 
  }, [userCode]);
        
  useEffect(()=>{
       setTimeout(()=>{
         if(!userCode === userData?.user?.user_code ){
              navigate("/login")
              sessionStorage.removeItem("user_code");
            }
       },6000)
     
  },[])


  const fetchUserDetails = async () => {
    try {
      const data = { user_code: userCode };
       
      const requestedData = await dispatch(getUserDetails(data))   
      if(getUserDetails.fulfilled.match(requestedData) && requestedData.payload){
        setUserData(requestedData.payload);
        // console.log("get user data is ", requestedData.payload)
      }else{
        setUserData([]);
      }
    } catch (error) {
      console.log("Error in fetching data:", error.message);
    }
  };


  if (!userData) {
    return <h1>Loading...</h1>;
  }

  const { upcoming_matches = [] , matches = [] } = userData; 
  
  
  const handleUserContestDetails = async (match_code) => {
    try {
      const data ={
          user_code: userData?.user?.user_code,
          match_code: match_code,    
      
        }
      
      const requestedData = await dispatch(UserContestDetails(data))
      if(UserContestDetails.fulfilled.match(requestedData) && requestedData.payload){
        // console.log(requestedData.payload)
        navigate(`/matchContest/${match_code}` , {state : requestedData.payload})
        
      }else if(UserContestDetails.fulfilled.match(requestedData) && requestedData.error){
        alert(requestedData.error)
      }
        
    } catch (error) {
      console.error("Error fetching contest details:", error);
    }
  };

  const handleJoinedContest = async (match)=>{ 
     try {
       const data = {
         //  user_code : userCode,
         user_code : "GMD935",
         match_code : "1252421"
         //  match_code : match?.match_code
       }
       
       const requestedData = await dispatch(JoinContest(data));
       if(JoinContest.fulfilled.match(requestedData) && requestedData.payload){
        navigate(`/joinedContest/${match.match_code}` , {state : requestedData.payload})
       }else{
          alert("try Again")
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  console.log(userData)

  return (
    <div className="min-h-[100vh] p-8 bg-gray-100">
      <div className="matchSelectBtn flex md:justify-evenly justify-between font-[Poppins] items-center mb-4">
        <button
          className={`md:px-5 px-2 md:w-[200px] w-[40%] h-[50px] md:h-auto md:py-2 py-2 text-[11px] md:text-[15px] border-0 text-white rounded-[5px] md:rounded-[13px] ${
            match === "live"
              ? "bg-green-700 hover:bg-green-600 underline"
              : "bg-blue-900 hover:bg-blue-500"
          } transition-all duration-300 ease-in-out`}
          onClick={() => setMatch("live")}
        >
           Matches
        </button>
        <button
          className={`md:px-5 px-2 md:w-[200px] w-[40%] h-[50px] md:h-auto md:py-2 py-2 text-[11px] md:text-[15px] border-0 text-white rounded-[5px] md:rounded-[13px] ${
            match === "finished"
              ? "bg-green-700 hover:bg-green-600 underline"
              : "bg-blue-900 hover:bg-blue-500"
          } transition-all duration-300 ease-in-out`}
          onClick={() => setMatch("finished")}
        >
          Finished Matches
        </button>
      </div>

      {match === "live" && (
        <div className="liveMatches">
          <h1 className="text-xl font-bold mb-4">Live Matches</h1>
           <h1>  No Live Matches</h1>
          {/* {userData?.user.length > 100 ? (
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {upcoming_matches?.map((match, i) => (
                <div
                  key={match.match_code}
                  className="bg-white shadow-lg rounded-lg p-4"
                >
                  <div className="img flex justify-between items-center mb-4">
                    <img
                      className="h-[50px] w-[50px] rounded-full"
                      src={`https://predictionist.in/${match.team1_logo}`}
                      alt={match.team1}
                    />
                    <span className="text-lg font-semibold">{match.desc1}</span>
                    <img
                      className="h-[50px] w-[50px] rounded-full"
                      src={`https://predictionist.in/${match.team2_logo}`}
                      alt={match.team2}
                    />
                  </div>
                  <div className="RemainingTime flex justify-center  mb-4">
                    <span><RemainingTime time={i} /></span>
                  </div>
                  <div className="join flex justify-center">
                    <NavLink
                      onClick={() => handleJoinedContest(match)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                      // to={`/matchContest/${match.match_code}`}
                      to={`/`}
                    >
                      Join
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          )} */}
        </div>
      )}
      {match === "live" && (
        <div className="liveMatches mt-6">
          <h1 className="text-xl font-bold mb-4">Upcoming Matches</h1>
          {upcoming_matches.length === 0 ? (
            <h1>No Upcoming Matches</h1>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {upcoming_matches.map((match, i) => (
                <div
                  key={match.match_code}
                  className="bg-white shadow-lg rounded-lg p-4"
                >
                  <div className="img flex justify-between items-center mb-4">
                    <img
                      className="h-[50px] w-[50px] rounded-full"
                      src={`https://predictionist.in/${match.team1_logo}`}
                      alt={match.team1}
                    />
                    <span className="text-lg font-semibold">{match.desc1}</span>
                    <img
                      className="h-[50px] w-[50px] rounded-full"
                      src={`https://predictionist.in/${match.team2_logo}`}
                      alt={match.team2}
                    />
                  </div>
                  {/* <p>{match.match_code}</p> */}
                  <div className="RemainingTime flex justify-center  mb-4">
                    <span><RemainingTime time={i} /></span>
                  </div>
                  <div className="join flex justify-center">
                    <NavLink
                      onClick={() => handleUserContestDetails(match.match_code)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                      // to={`/matchContest/${match.match_code}`}
                      to={`/`}
                    >
                      Join
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {match === "finished" && (
        <div className="finished">
          <h1 className="text-xl font-bold">Finished Matches</h1>
          {upcoming_matches.length === 0 ? (
            <h1>No Live Matches</h1>
          ) : ( <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {matches.map((match, i) => (
              <div
                key={match.match_code}
                className="bg-white shadow-lg rounded-lg p-4"
              >
                <div className="img flex justify-between items-center mb-4">
                  <img
                    className="h-[50px] w-[50px] rounded-full"
                    src={`https://predictionist.in/${match.team1_logo}`}
                    alt={match.team1}
                  />
                  <span className="text-lg font-semibold">{match.desc1}</span>
                  <img
                    className="h-[50px] w-[50px] rounded-full"
                    src={`https://predictionist.in/${match.team2_logo}`}
                    alt={match.team2}
                  />
                </div>
                <div className="RemainingTime flex justify-center  mb-4">
                  <span><RemainingTime time={i} /></span>
                </div>
                <div className="join flex justify-center">
                  <NavLink
                    onClick={() => handleJoinedContest(match)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    to={`/`}
                  >
                    Join
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
           ) }


        </div>

      )}
    </div>
  );
}

export default Home;
