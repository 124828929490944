import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";


const Protected = ({ children }) => {
  const [flag, setFlag] = useState(null);
  const cookies = new Cookies();
  const cookie = cookies.get("accessToken");
  const navigate = useNavigate();
  const user = (sessionStorage.getItem("user"))
  const userCode = sessionStorage.getItem("user_code");
  useEffect(() => {
    if(cookie && userCode) {
      setFlag(true)
    }else{
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("user_code");
      navigate("/login");
    }
  },[cookie, user, navigate]);
  if (flag === null){
    <div className="mt-[50%]">
      <h2 className="text-center font-bold text-[30px] mt-6">Loading...</h2>  
      {/* <Loader color={"black"} size={40} bgOpacity={0.1} speed={0.8} stroke={5}/> */}
    </div>
  }else{
    return children;
  }
};

export default Protected;
