import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { TeamUpdate } from '../../Reducers/TeamContests/Contests';

function EditTeam() {
    const [team, setTeam] = useState({});
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState({});
    const [teamSelectionCount, setTeamSelectionCount] = useState({ A: 0, B: 0 });
    const [isLoading, setIsLoading] = useState(true);  
    const userCode = sessionStorage.getItem("user_code")       

    const { matchId } = useParams();
    const location = useLocation();
    const receivedData = location.state || {};
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { players = [], user_team = {} , teams , user  } = receivedData;
  

    const playersRole = [
        "Your top goal scorer",
        "Your key assister",
        "Your team captain",
        "Your vice-captain",
        "Your goalkeeper",
        "Your most accurate passer",
        "Your top interceptor",
        "Your player with the most shots on target",
        "Your best tackler",
        "Your most creative player",
    ];

    

    useEffect(() => {
        const initialTeam = {};
        const initialSelectedPlayers = {};
        const initialTeamSelectionCount = { A: 0, B: 0 };

        Object.keys(user_team).forEach((key) => {
            if (key.startsWith('player')) {
                const player = players.find(p => p.player_code === user_team[key]);
                if (player) {
                    initialTeam[key] = player.player_code;
                    initialSelectedPlayers[player.player_code] = (initialSelectedPlayers[player.player_code] || 0) + 1;
                    initialTeamSelectionCount[player.team_code] += 1;
                }
            }
        });

        setTeam(initialTeam);
        setSelectedPlayers(initialSelectedPlayers);
        setTeamSelectionCount(initialTeamSelectionCount);
    }, [user_team, players]);

    const handlePlayerChange = (playerKey, playerValue , player) => {
        const prevPlayerCode = team[playerKey];
        const playerTeamCode = players.find(p => p.player_code === playerValue).team_code;

        setTeam(prevTeam => ({
            ...prevTeam,
            [playerKey]: playerValue
        }));

        setSelectedPlayers(prevSelectedPlayers => {
            const newSelectedPlayers = { ...prevSelectedPlayers };
            if (prevPlayerCode) {
                newSelectedPlayers[prevPlayerCode] -= 1;
            }
            newSelectedPlayers[playerValue] = (newSelectedPlayers[playerValue] || 0) + 1;
            return newSelectedPlayers;
        });

        setTeamSelectionCount(prevCount => {
            const newCount = { ...prevCount };
            if (prevPlayerCode) {
                const prevPlayerTeam = players.find(p => p.player_code === prevPlayerCode).team_code;
                newCount[prevPlayerTeam] -= 1;
            }
            newCount[playerTeamCode] += 1;
            return newCount;
        });

        setOpenDropdown(null);
    };

    const handleDropdownToggle = (playerKey) => {
        setOpenDropdown(openDropdown === playerKey ? null : playerKey);
    };

    const isPlayerBlocked = (playerCode) => {
        return selectedPlayers[playerCode] >= 2;
    };

    const isTeamBlocked = (teamCode) => {
        return teamSelectionCount[teamCode] >= 6;
    };

  

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reformattedPlayers = {};
        Object.keys(team).forEach((key, index) => {
            const playerCode = team[key];
            const player = players.find(p => p.player_code === playerCode);
            
            if (player) {
                reformattedPlayers[`player${index}`] = {
                    player_code: player.player_code,
                    team_code: player.team_code,
                };
            }
        });
    

        const data = {
            user_code: userCode,
            userteam_code: user_team?.userteam_code,
            players: reformattedPlayers,
            match_code: user_team?.match_code,
            power1: user_team?.power1,
            power2: user_team?.power2,
        };
      
        console.log("data is :" , data)

        try {
           const req1 = await dispatch(TeamUpdate(data));
           console.log(req1.payload)
           if(TeamUpdate.fulfilled.match(req1) && req1.payload.success){
            console.log("success team edit success :" , req1.payload)
              setTimeout(()=>{
                  navigate(-1)
              },3000)
           }else{
            alert(req1.payload.error);
           }
           
        } catch (error) {
            console.error("Error in Edit:", error.message);
            alert("Error in Edit");
        }
    };

    
    useEffect(() => {
        if (user?.match_code !== matchId) {
            setIsLoading(true);  
            const timer = setTimeout(() => {
                navigate(-1);
            }, 0);
          
            return () => clearTimeout(timer);
        } else {
            setIsLoading(false);
        }
    }, [matchId, navigate, user?.match_code]);


    if (isLoading) {
        return <h1>Wait...</h1>; 
    }   




    return (
        <div className="max-w-2xl mx-auto bg-gradient-to-br from-blue-600 via-purple-500 to-pink-500 text-white rounded-lg shadow-xl p-8">
            <h2 className="text-3xl font-bold mb-8 text-center tracking-wide">Edit Your Dream Team</h2>
            <form >
                {Object.keys(team).map((playerKey, index) => (
                    <div key={index} className="mb-2 flex w-[100%] justify-evenly items-center relative">
                        <div className="relative w-[100%]">
                            <button
                                type="button"
                                className={`w-full px-4 py-3 flex justify-between items-center rounded-lg border-2 border-transparent bg-white text-gray-700 shadow-md focus:outline-none transition-colors duration-300 ease-in-out ${isTeamBlocked(players.find(p => p.player_code === team[playerKey])?.team_code) ? 'cursor-not-allowed' : ''}`}
                                onClick={() => handleDropdownToggle(playerKey)}
                                disabled={isTeamBlocked(players.find(p => p.player_code === team[playerKey])?.team_code)}
                            >
                                {team[playerKey]
                                    ? players.find(p => p.player_code === team[playerKey]).player_name + ` (${playersRole[index]})`
                                    : "Select a player"}
                                <FaChevronDown className="ml-2 text-gray-500" />
                            </button>
                            {openDropdown === playerKey && (
                                <div className="absolute top-full left-0 right-0 z-10 bg-white border border-gray-300 rounded-lg shadow-lg mt-2 max-h-60 overflow-y-auto">
                                    {players.map(player => (
                                        <div
                                            key={player.player_code}
                                            className={`flex items-center p-2 ${isPlayerBlocked(player.player_code) || isTeamBlocked(player.team_code) ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100 cursor-pointer'}`}
                                            onClick={() => !isPlayerBlocked(player.player_code) && !isTeamBlocked(player.team_code) && handlePlayerChange(playerKey, player.player_code , player)}
                                        >
                                            <img
                                                src={`https://predictionist.in/${player.photo}`}
                                                alt={player.player_name}
                                                className="h-8 w-8 object-cover rounded-full mr-2"
                                            />
                                            <span className="text-gray-700">{player.player_name}</span>
                                            <span className="ml-auto text-sm text-gray-500">
                                                {player.position} - {player.team_name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                
            </form>
            <button onClick={handleSubmit}
                    className="w-full py-3 mt-6 bg-pink-600 hover:bg-pink-700 text-white font-bold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                    Save Team
                </button>
        </div>
    );
}

export default EditTeam;
