import React from 'react';

function PrizePoolPage({ ContestDetails }) {
  const { prize_pool, filled_spot, prize1, prize2, prize3, prize4, prize5, prize6 } = ContestDetails;

  // Create an array of prizes to map through
  const prizes = [
    { prize: prize1 || 'N/A' },
    { prize: prize2 || 'N/A' },
    { prize: prize3 || 'N/A' },
    { prize: prize4 || 'N/A' },
    { prize: prize5 || 'N/A' },
    { prize: prize6 || 'N/A' }
  ];
  
  return (
    <div className=' min-h-[40vh] w-full mb-4 px-6'>
      <h1 className='text-xl font-semibold mb-4 text-center' >Prize Pool: {prize_pool}</h1>
      <h2 className='text-lg mb-2 text-center'>Filled Spots: {filled_spot}</h2>

      {/* Prize Table */}
      <table className='table-auto w-full bg-white shadow-md rounded-lg'>
        <thead>
          <tr className='bg-indigo-500 text-white'>
            <th className='py-2 px-4'>Rank</th>
            <th className='py-2 px-4'>Prize</th>
          </tr>
        </thead>
        <tbody>
          {prizes.map((prize, index) => (
            <tr key={index} className='text-center hover:bg-indigo-200 transition-all duration-250'>
              <td className='border py-2 px-4'>{index+1}</td>
              <td className='border py-2 px-4'>{prize.prize}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PrizePoolPage;
