import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";

function CreateTeam() {
  const { matchId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPlayers, setSelectedPlayers] = useState(Array(10).fill(null));
  const [totalSelectedPlayers, setTotalSelectedPlayers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [mobileNumber, setMobileNumber] = useState(sessionStorage.getItem("mobile_number") || "");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const [power1Available, setPower1Available] = useState(false);
  const [power2Available, setPower2Available] = useState(false);
  const [power1Selected, setPower1Selected] = useState(false);
  const [power2Selected, setPower2Selected] = useState(false);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (!location.state || !sessionStorage.getItem("user_code")) {
      // If there's no state or mobile number, redirect to home page
      navigate("/");
    }
  }, [location.state, navigate]);

  const receivedData = location.state?.data || {};
  const receivedUser = location.state?.user || {};
  
  const allPlayers = receivedData?.players || [];
  const user = receivedData?.user || {};
  const teams = receivedData?.teams || {};
  
  const teamsArray = Object.values(teams);

  const questionTexts = [
    "Your top goal scorer",
    "Your key assister",
    "Your team captain",
    "Your vice-captain",
    "Your goalkeeper",
    "Your most accurate passer",
    "Your top interceptor",
    "Your player with the most shots on target",
    "Your best tackler",
    "Your most creative player",
  ];

  useEffect(() => {
    if (user) {
      setPower1Available(user.power1);
      setPower2Available(user.power2);
    }
  }, [user]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handlePlayerSelect = (player) => {
    const updatedPlayers = [...selectedPlayers];
    const updatedTotalSelectedPlayers = [...totalSelectedPlayers];

    const playerCode = player.player_code;
    const playerTeamCode = player.team_code;

    const playerCount = updatedTotalSelectedPlayers.filter(
      (p) => p.player_code === playerCode
    ).length;
    const teamPlayerCount = updatedTotalSelectedPlayers.filter(
      (p) => p.team_code === playerTeamCode
    ).length;

    if (playerCount >= 2 && !updatedPlayers.includes(player)) return;
    if (teamPlayerCount >= 6 && !updatedPlayers.includes(player)) return;

    const previousPlayer = updatedPlayers[currentQuestion];
    if (previousPlayer) {
      const previousPlayerIndex = updatedTotalSelectedPlayers.indexOf(previousPlayer);
      if (previousPlayerIndex !== -1) {
        updatedTotalSelectedPlayers.splice(previousPlayerIndex, 1);
      }
    }

    updatedPlayers[currentQuestion] = player;
    updatedTotalSelectedPlayers.push(player);

    setSelectedPlayers(updatedPlayers);
    setTotalSelectedPlayers(updatedTotalSelectedPlayers);
  };

  const handleQuestionChange = (index) => {
    setCurrentQuestion(index);

    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const isPlayerSelectedInCurrentQuestion = (player) => {
    return selectedPlayers[currentQuestion] === player;
  };

  const canSelectPlayer = (player) => {
    const playerCode = player.player_code;
    const playerTeamCode = player.team_code;

    const playerCount = totalSelectedPlayers.filter(
      (p) => p.player_code === playerCode
    ).length;
    const teamPlayerCount = totalSelectedPlayers.filter(
      (p) => p.team_code === playerTeamCode
    ).length;

    return playerCount < 2 && teamPlayerCount < 6;
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [currentQuestion]);
  
  const handleSubmit = async () => {
    const formattedPlayers = selectedPlayers.reduce((acc, player, index) => {
      if (player) {
        acc[`player${index}`] = {
          player_code: player.player_code,
          team_code: player.team_code,
        };
      }
      return acc;
    }, {});
  
    const data = {
      match_code: user?.match_code,
      user_code: receivedUser,
      players: formattedPlayers,
    };
       
    try {
      const response = await axios.post(
        "https://predictionist.in/api/adding_userteam.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if(response.data){
        navigate(-1)    
      }
     
      alert("Team submitted successfully");
    } catch (error) {
      console.error("Error submitting team:", error.message);
      alert("Failed to submit team. Please try again.");
    }
  };

  const openPopup = () => {
    setPopupData({
      matchId: user?.match_code,
      mobileNumber: user?.mobile_number,
      players: selectedPlayers.map((player) => ({
      photo: player ? player.photo : null,
      name: player ? player.player_name : null,
      team_name: player ? player.team_name : null,
      player_code: player ? player.team_name : null,
      })),
    });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handlePower1Click = () => {
    if (power1Available) {
      setPower1Selected((prev) => !prev);
    }
  };

  const handlePower2Click = () => {
    if (power2Available) {
      setPower2Selected((prev) => !prev);
    }
  };

  const handlePopupSubmit = () => {
    handleSubmit();
    closePopup();
  };

  return (
    <div className="p-4">
      {/* Header Section */}
      <div className="flex items-center mb-4">
        <button onClick={handleBackClick} className="text-blue-500">
          <FaArrowLeft className="h-6 w-6" />
        </button>
        <h1 className="text-lg font-bold ml-2">
          Create Team for Match ID: {matchId}
        </h1>
      </div>

      {/* Match Details */}
      {teamsArray.length === 2 && (
        <div className="flex mb-3 justify-center items-center p-2">
          {/* First Team */}
          <div className="flex flex-col items-center">
            <img
              src={`https://predictionist.in/${teamsArray[0].logo}`}
              alt={`${teamsArray[0].team_name} logo`}
              className="w-14 h-14"
            />
          </div>

          {/* VS Text */}
          <div className="text-xl font-bold mx-4">VS</div>

          {/* Second Team */}
          <div className="flex flex-col items-center">
            <img
              src={`https://predictionist.in/${teamsArray[1].logo}`}
              alt={`${teamsArray[1].team_name} logo`}
              className="w-14 h-14"
            />
          </div>
        </div>
      )}

      {/* Question Progress */}
      <div className="flex justify-center items-center mb-4">
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={index}
            className={`w-10 h-10 border-2 mx-1 ${
              selectedPlayers[index] ? "bg-green-500" : "bg-gray-300"
            }`}
          ></div>
        ))}
      </div>

      {/* Question Buttons */}
      <div className="flex justify-center mb-4">
        {Array.from({ length: 10 }).map((_, index) => (
          <button
            key={index}
            onClick={() => handleQuestionChange(index)}
            className={`px-4 py-2 mx-1 border ${
              index === currentQuestion
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            QUESTION {index + 1}
          </button>
        ))}
      </div>

      {/* Current Question */}
      <div className="text-center mb-4">
        <h2 className="text-lg font-semibold">
          {questionTexts[currentQuestion]}
        </h2>
      </div>

      {/* Player Selection Box */}
      <div
        ref={scrollRef}
        className="flex flex-col mb-4 border max-h-64 overflow-y-scroll"
      >
        {allPlayers.map((player, index) => (
          <div
            key={index}
            onClick={() =>
              canSelectPlayer(player) && handlePlayerSelect(player)
            }
            className={`flex justify-between items-center p-2 border-b ${
              isPlayerSelectedInCurrentQuestion(player)
                ? "bg-blue-500 text-white"
                : canSelectPlayer(player)
                ? "bg-gray-100 cursor-pointer hover:bg-blue-200"
                : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            <img
              src={`https://predictionist.in/${player.photo}`}
              alt={player.player_name}
              className="w-12 h-12"
            />
            <div className="ml-2 flex-grow">
              <p className="font-semibold">{player.player_name}</p>
              <p className="text-sm text-gray-500">{player.team_name}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg">
        <button
          onClick={openPopup}
          className="w-full bg-blue-500 text-white py-2 rounded"
          disabled={selectedPlayers.includes(null)}
        >
          Submit Team
        </button>
      </div>

      {/* Power Buttons */}
      <div className="flex justify-center items-center mt-4">
        <button
          onClick={handlePower1Click}
          className={`px-4 py-2 mx-2 border ${
            power1Selected
              ? "bg-green-500 text-white"
              : "bg-gray-200 cursor-pointer"
          }`}
          disabled={!power1Available}
        >
          Power1
        </button>
        <button
          onClick={handlePower2Click}
          className={`px-4 py-2 mx-2 border ${
            power2Selected
              ? "bg-green-500 text-white"
              : "bg-gray-200 cursor-pointer"
          }`}
          disabled={!power2Available}
        >
          Power2
        </button>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0  flex items-center justify-center  bg-black bg-opacity-50">
          <div className="bg-white py-2 px-4  mt-16 rounded shadow-lg">
            <h2 className="text-lg font-bold mb-2 ">Confirm Submission</h2>
            <p>Match ID: {popupData?.matchId}</p>
            <p>Mobile Number: {popupData?.mobileNumber}</p>
            <h3 className="text-md font-semibold mt-2">Selected Players:</h3>

            <ul className="md:h-[300px] h-[60vh] md:min-w-[400px]  overflow-scroll">
              {popupData.players.map((player, index) => (
                <li
                  key={index}
                  className="flex md:text-[14px] text-[10px] justify-between items-center px-4 py-2 mb-1 bg-indigo-200 font-[poppins]"
                >
                  <span className=" flex items-center gap-2">
                    <img
                      src={`https://predictionist.in/${player.photo}`}
                      alt={player.name}
                      className=" h-[2rem] w-[2rem] object-cover"
                    />
                    {player.name}
                  </span>
                  <span className="">{player.team_name}</span>
                </li>
              ))}
            </ul>

            <div className="flex justify-between items-center p-4">
              <div className="mt-4 flex justify-center">
                <button
                  onClick={handlePower1Click}
                  className={`px-2 py-2 rounded mr-2 ${
                    power1Available
                      ? power1Selected
                        ? "bg-yellow-400"
                        : "bg-green-600"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                >
                  2X
                </button>
                <button
                  onClick={handlePower2Click}
                  className={`px-2 py-2 rounded mr-2  ${
                    power2Available
                      ? power2Selected
                        ? "bg-yellow-400"
                        : "bg-green-600"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                >
                  3X
                </button>
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  onClick={closePopup}
                  className="px-4 py-2 bg-gray-300 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handlePopupSubmit}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        )}
    </div>
  );
}

export default CreateTeam;
