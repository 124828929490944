import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useParams,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  EditTeam,
  JoinContest2,
  JoiningTeam,
  TeamMaker,
} from "../../Reducers/TeamContests/Contests";
import JoinContest from "../JoinContest/JoinContest";
import { MatchRank } from "../../Reducers/UserDetails/userDetails";

function JoinUpcomingMatch() {
  const [userContest, setUserContest] = useState(null);
  const { matchId } = useParams();
  const [view, setView] = useState("all");
  const [showPopUp, setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = useState("");
  const [getData, setGetData] = useState(null);

  const location = useLocation();
  const receivedData = location.state || {};
  const userTeams = userContest?.user_teams;
  const dispatch = useDispatch();
  const userCode = sessionStorage.getItem("user_code");

  const [privateFormData, setPrivateFormData] = useState({
    user_code: userCode,
    contest_code: "",
    password: "",
  });
  const [showPublicPopUp, setShowPublicPopUp] = useState(false);
  const [reqData, setReqData] = useState(null);
  const [reqTeam, setReqTeam] = useState("");
  const [firstProof, setFirstProof] = useState(null);
  const [secondProof, setSecondProof] = useState(null);
  const [wait, setWait] = useState(false);
  //  console.log(reqTeam)
  
  const [requestForm, setRequestForm] = useState({
    user_code: userCode,
    contest_code: "",
    userteam_code: reqTeam,
    match_code: "",
    free_join: "",
    free_entry: userContest?.free_entry,
    first_proof: "",
    second_proof: "",
  });
  const [copySuccess, setCopySuccess] = useState(false);
  console.log("data is :", receivedData)

  const handleCopy = () => {
    navigator.clipboard.writeText(reqData?.condition_link).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };
  // Handle input changes
  const handleChangeFileProve = (e) => {
    const { name, value } = e.target;
    setRequestForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handlePrivateDataChange = (e) => {
    const { name, value } = e.target;
    setPrivateFormData({
      ...privateFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!userCode === receivedData?.user?.user_code) {
      navigate("/");
      localStorage.removeItem("NewData");
      sessionStorage.removeItem("user_code");
    }
  }, []);

  useEffect(() => {
    setUserContest(receivedData);
  }, [matchId]);

  const handleClosePopup = () => {
    setShowPopUp(false);
  };

  if (!userContest) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h2 className="text-2xl font-semibold font-poppins">Loading...</h2>
      </div>
    );
  }

  //  console.log(userContest)

  const handleCreateTeam = async () => {
    try {
      const data = {
        user_code: userCode,
        match_code: matchId,
      };
      const req = await dispatch(TeamMaker(data));

      if (TeamMaker.fulfilled.match(req) && req.payload.user) {
        console.log(req.payload);
        navigate(`/createTeam/${matchId}`, {
          state: { data: req.payload, user: userCode },
        });
      } else {
        alert("try again");
      }
    } catch (error) {
      console.log("error in create team", error);
    }
  };

  const handleEditTeam = async (teamCode) => {
    const data = {
      user_code: userCode,
      match_code: matchId,
      userteam_code: teamCode,
    };

    try {
      const req1 = await dispatch(EditTeam(data));
      if (EditTeam.fulfilled.match(req1) && req1.payload.user) {
        navigate(`/editTeam/${matchId}`, { state: req1.payload });
      }
    } catch (error) {
      console.log("error in create team", error);
    }
  };

  const handleSelectTeam = (data) => {
    if (data.join_limit > 0) {
      if (data.free_join_limit > 0) {
        if (data.free_join > 0) {
          setShowPopUp(true);
          setGetData({
            ...data,
            free_join: data.free_join,
            free_entry: 0,
          });
        } else if (userContest?.free_entry > 0) {
          setShowPopUp(true);
          setGetData({
            ...data,
            free_join: 0,
            free_entry: userContest?.free_entry,
          });
        } else {
          alert("join by Amount free_join reason");
          setShowPopUp(true);
          setGetData({
            ...data,
            free_join: 0,
            free_entry: 0,
          }); // join by amount
          console.log(getData);
        }
      } else {
        alert("join by Amount free_join_limit");
        // join by amount
        setShowPopUp(true);
        setGetData({
          ...data,
          free_join: 0,
          free_entry: 0,
        });
      }
    } else {
      alert("You can't Join this");
    }
  };

  const handleJoinContestWithTeam = async (selectTeam) => {
    const userT = userTeams.find((team) => team.userteam_code === selectTeam);
    if (userT && getData) {
      const data = {
        user_code: userCode,
        contest_code: getData?.contest_code,
        userteam_code: userT?.userteam_code,
        match_code: matchId,
        joining_fee: getData?.entry_fee,
        free_join: getData?.free_join,
        free_entry: userContest?.free_entry,
      };

      try {
        const req1 = await dispatch(JoiningTeam(data));
        if (JoiningTeam.fulfilled.match(req1) && req1.payload.success) {
          console.log("***", req1.payload);
          // console.log(req1.payload)
        } else if (JoiningTeam.fulfilled.match(req1) && req1.payload.message) {
          console.log("not enough balance", req1.payload.message);
          alert(req1.payload.message);
          console.log(req1.payload);
        } else {
          alert(req1.payload.error);
          console.log("error is ", req1.payload.error);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      console.log("No team found with the selected code");
    }

    setTimeout(() => {
      setShowPopUp(false);
    }, 1000);
  };

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  // console.log(userContest)
  const handleJoinContest = async (contest) => {
    const data = {
      user_code: userCode,
      match_code: matchId,
      contest_code: contest,
    };
    console.log(data);

    try {
      const req1 = await dispatch(MatchRank(data));
      if (MatchRank.fulfilled.match(req1) && req1?.payload.error) {
        alert(req1.payload.error);
      } else if (
        MatchRank.fulfilled.match(req1) &&
        req1?.payload?.match_details?.update_status === "pending"
      ) {
        alert("Match is live now you can see your rank after match finished");
      } else if (
        MatchRank.fulfilled.match(req1) &&
        req1?.payload?.match_details.update_status !== "pending"
      ) {
        alert("success", req1.payload);
        navigate(`/ranking/${contest}`, { state: req1.payload });
      } else {
        alert("try again");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCreateContest = () => {
    navigate(`/createContest/${matchId}`);
  };

  const handleSeeMoreAboutContest = async (contest) => {
    try {
      const req = await axios.post(
        "https://predictionist.in/api/created_contest.php",
        {
          user_code: userCode,
          contest_code: contest?.contest_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(req.data);
      navigate(`/View_Contest/${contest?.contest_code}`, { state: req.data });
    } catch (error) {
      console.log(error.message);
    }
    // console.log(contest)
  };

  // console.log( "user Contest data",userContest)

  const handleFileChange = (e, proofType) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      let base64String = reader.result;
      base64String = base64String.replace(/^data:image\/[a-z]+;base64,/, "");
      if (proofType === "firstProof") {
        setFirstProof(base64String);
        setRequestForm((prevForm) => ({
          ...prevForm,
          first_proof: base64String,
        }));
      } else {
        setSecondProof(base64String);
        setRequestForm((prevForm) => ({
          ...prevForm,
          second_proof: base64String,
        }));
      }
    };
    reader.readAsDataURL(file);
  };

  const handleShowPublicPopUp = (contest) => {
    if (userTeams[0]?.userteam_code) {
      setShowPublicPopUp(true);
      setReqData(contest);
      console.log(contest);
      setRequestForm((prev) => ({
        ...prev,
        contest_code: contest.contest_code,
        match_code: contest.match_code,
        free_join: contest?.remaining_free_join,
        free_entry: userContest?.free_entry,
      }));
    } else {
      setShowPopUp(true);
    }
  };

  const handleSubmitPublicTeamJoinReq = async (e) => {
    e.preventDefault();
    setWait(true);
    if (requestForm.free_join === "0" && requestForm.free_entry === "0") {
      alert("You have no free joins or free entries left.");
      return;
    }
    const data = {
      ...requestForm,
      userteam_code: reqTeam || requestForm.userteam_code,
      free_join: requestForm.free_join > 0 ? 1 : 0,
      free_entry: requestForm.free_entry > 0 ? 1 : 0,
    };

    if (requestForm.free_join === "0") {
      if (requestForm.free_entry > 0) {
        console.log("Using free entry.");
      } else {
        return;
      }
    }
    console.log(data);
    try {
      const response = await axios.post(
        "https://predictionist.in/api/special_joining.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setWait(false);
    } catch (error) {
      console.error(error.message);
      setWait(false);
    }
  };

  const handleSearchPrivateContest = async () => {
    const data = {
      user_code: privateFormData?.user_code,
      contest_code: privateFormData?.contest_code,
      password: privateFormData?.password,
    };
    console.log(data);
    try {
      const req = await axios.post(
        "https://predictionist.in/api/private_contest.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("reacived data ", req.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  
  return (
    <div className="p-6 max-w-5xl mx-auto relative font-[poppins]">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 flex flex-row items-center justify-center">
        Join Match{" "}
        <span className="text-[10px] mt-3">( Match ID: {matchId} )</span>
      </h1>
      <div className="flex justify-center mb-8">
        <button
          onClick={() => setView("all")}
          className={`md:px-4 px-2 md:py-2 py-0 mx-2 md:text-[16px] text-[9px] rounded-[4px] md:rounded-xl font-semibold  ${
            view === "all"
              ? "bg-indigo-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          All Contest
        </button>
        <button
          onClick={() => setView("picked")}
          className={`md:px-4 px-2 md:py-2 py-0 mx-2 md:text-[16px] text-[9px] rounded-[4px] md:rounded-xl font-semibold  ${
            view === "picked"
              ? "bg-indigo-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          Picked Contest
        </button>
        <button
          onClick={() => setView("joined")}
          className={`md:px-4 px-2 md:py-2 py-0 mx-2 md:text-[16px] text-[9px] rounded-[4px] md:rounded-xl font-semibold  ${
            view === "joined"
              ? "bg-indigo-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          Joined Contest
        </button>
        <button
          onClick={() => setView("createContest")}
          className={`md:px-4 px-2 md:py-2 py-0 mx-2 md:text-[16px] text-[9px] rounded-[4px] md:rounded-xl font-semibold  ${
            view === "createContest"
              ? "bg-indigo-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          Create Contest
        </button>
        <button
          onClick={() => setView("privateContest")}
          className={`md:px-4 px-2 md:py-2 py-0 mx-2 md:text-[16px] text-[9px] rounded-[4px] md:rounded-xl font-semibold  ${
            view === "privateContest"
              ? "bg-indigo-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          Private Contest
        </button>
      </div>

      <div className="">
        {view === "all" && (
          <h2 className="mb-4 md:text-2xl text-lg font-semibold  md:w-[34%] w-[80%] rounded-l-md text-blue-500 px-2 bg-gradient-to-r from-indigo-300 to-slate-50 py-2">
            All Contests
          </h2>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {view === "all" &&
            userContest?.all_contests?.map((contest, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 hover:shadow-2xl transition-shadow duration-300"
              >
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Contest Code: {contest.contest_code}
                </h2>
                <p className="text-gray-600">
                  Prize Pool: {contest.prize_pool}
                </p>
                <p className="text-gray-600">
                  Filled Spots: {contest.filled_spots || "N/A"}
                </p>
                <button
                  onClick={() => handleSelectTeam(contest)}
                  className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                >
                  {contest.free_join
                    ? "Free Entry"
                    : `Join Contest only ₹${contest.entry_fee}`}
                </button>
              </div>
            ))}
        </div>
        <div className="mt-10">
          {view === "all" && (
            <h2 className="mb-4 md:text-2xl text-lg font-semibold  md:w-[34%] w-[80%] rounded-l-md text-blue-500 px-2 bg-gradient-to-r from-indigo-300 to-slate-50 py-2">
              Public Contest
            </h2>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            {view === "all" &&
              userContest?.public_contests?.map((contest, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 hover:shadow-2xl transition-shadow duration-300"
                >
                  <h2 className="text-xl font-semibold mb-2 text-gray-800">
                    Contest Code: {contest.contest_code}
                  </h2>
                  <p className="text-gray-600">
                    Prize Pool: {contest.prize_pool}
                  </p>
                  <p className="text-gray-600">
                    Filled Spots: {contest.filled_spots || "N/A"}
                  </p>
                  <button
                    onClick={() => handleShowPublicPopUp(contest)}
                    className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                  >
                    Join Contest
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>

      {showPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-[1px] flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full animate-slideIn">
            <h2 className="text-2xl font-semibold mb-4">Join Contest</h2>

            {userTeams[0]?.userteam_code ? (
              <>
                {" "}
                <p className="text-gray-700 mb-6">
                  <label
                    htmlFor="teamSelect"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Select Team
                  </label>
                  <select
                    id="teamSelect"
                    value={selectedTeam}
                    onChange={handleChange}
                    className="block w-full mt-1 border border-gray-300 rounded-lg p-2"
                  >
                    <option value="">Select team</option>
                    {userTeams.map((team, index) => (
                      <option key={index} value={team.userteam_code}>
                        {team.userteam_code}
                      </option>
                    ))}
                  </select>
                </p>
                <div className="flex justify-evenly items-center">
                  <button
                    onClick={() => handleJoinContestWithTeam(selectedTeam)}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-200 ease-in-out"
                  >
                    Join
                  </button>
                  <button
                    onClick={handleClosePopup}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-200 ease-in-out"
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                {" "}
                <h2>Teams Not Found</h2>
                <div className="flex mt-4 justify-evenly items-center">
                  <button
                    className="bg-indigo-400 px-3 py-2 rounded-[5px] text-white font-[poppins]"
                    onClick={handleCreateTeam}
                  >
                    Create Team
                  </button>
                  <button
                    onClick={handleClosePopup}
                    className="bg-red-500  text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-200 ease-in-out"
                  >
                    Close Join
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {showPublicPopUp && (
        <div className="fixed top-0 left-0 h-full w-full backdrop-blur-md flex justify-center items-center">
          <div className="relative bg-white rounded-lg shadow-xl py-4 px-6 border max-h-[75vh] mt-10 md:w-[70vw] w-[95vw] max-w-[600px]  overflow-y-scroll">
            <button
              onClick={() => setShowPublicPopUp(false)}
              className="absolute right-0 top-0 bg-red-600 px-4 py-2 text-white font-bold hover:bg-red-700 transition-all duration-200"
            >
              X
            </button>
            <form
              className="flex flex-col gap-4 mt-4"
              onSubmit={handleSubmitPublicTeamJoinReq}
            >
              {/* Contest Code */}
              <div className="flex flex-col">
                <label>Contest Code</label>
                <input
                  type="text"
                  name="contest_code"
                  readOnly
                  value={reqData?.contest_code || ""}
                  onChange={handleChangeFileProve}
                  className="p-2 border rounded-md "
                />
              </div>
              {/* Team Selection */}
              <div className="flex flex-col">
                <label htmlFor="teamSelect">Select team</label>
                {userTeams[0]?.userteam_code && (
                  <select
                    id="teamSelect"
                    name="userteam_code"
                    value={reqTeam}
                    onChange={(e) => setReqTeam(e.target.value)}
                    className="p-2 border rounded-md"
                  >
                    <option value="">Select team</option>
                    {userTeams.map((team, index) => (
                      <option key={index} value={team.userteam_code}>
                        {team.userteam_code}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <input
                type="number"
                name="free_join"
                value={requestForm.free_join}
                onChange={handleChangeFileProve}
                className="p-2 border rounded-md hidden"
                readOnly
              />

              {/* Free Entry */}
              <input
                type="number"
                name="free_entry"
                // placeholder="Free Entry"
                value={requestForm.free_entry}
                onChange={handleChangeFileProve}
                className="p-2 border rounded-md hidden"
                readOnly
              />
              
              <div  className="">
              <div className="flex flex-col items-center p-4">
              {reqData?.condition.length > 0 &&  <h2 className="text-center text-xl font-semibold mb-2">
                  Condition: {reqData?.condition}
                </h2>
                 }
                <div className="flex items-center gap-2 bg-slate-300 w-[100%] py-2 px-2 rounded-lg justify-between">
                  <h3 className="text-center text-lg">
                    Link:{" "}
                    <a
                      className="text-blue-500 hover:underline"
                      href={reqData?.condition_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {reqData?.condition_link.slice(0, 14) + "..."}
                    </a>
                  </h3>
                  <button
                    onClick={handleCopy}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition"
                  >
                    Copy
                  </button>
                </div>
                {copySuccess && (
                  <p className="text-green-500 text-[9px] mt-1">
                    Link copied to clipboard!
                  </p>
                )}
                <p className="text-red-400 text-center text-[13px] mt-3">
                  Note: Please upload a screenshot.
                </p>
              </div>
              {/* First Proof Upload */}
              <div className="flex flex-col">
                <label htmlFor="firstProof">First Proof:</label>
                <input
                  type="file"
                  id="firstProof"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "firstProof")}
                  className="p-2 border rounded-md"
                  required
                />
              </div>

              {/* Second Proof Upload */}
              <div className="flex flex-col">
                <label htmlFor="secondProof">Second Proof:</label>
                <input
                  type="file"
                  id="secondProof"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "secondProof")}
                  className="p-2 border rounded-md"
                  required
                />
              </div>
              </div>
              <button
                type="submit"
                className={`text-white py-2 px-4 rounded-md ${
                  wait === false
                    ? "bg-[#9e9ec9] cursor-not-allowed"
                    : "bg-[blue] cursor-pointer"
                }`}
              >
                {wait ? "Wait..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
      {view === "picked" && (
        <>
          {userContest?.user_teams &&
            userContest?.user_teams[0]?.userteam_code &&
            userContest?.user_teams?.length >= 1 && (
              <div className="flex flex-wrap justify-center gap-4">
                {userContest?.user_teams?.map((contest, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-lg rounded-xl p-4 border border-gray-200 hover:shadow-2xl transition-shadow duration-300 w-full sm:w-[48%] md:w-[45%] lg:w-[30%] h-auto"
                  >
                    <h2 className="text-xl font-semibold mb-2 text-gray-800">
                      User Team Code: {contest.userteam_code}
                    </h2>
                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => handleEditTeam(contest.userteam_code)}
                        className="mt-6 w-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-2 px-2 md:px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                      >
                        Edit Team
                      </button>
                      <button
                        onClick={() => handleEditTeam(contest.userteam_code)}
                        className="mt-6 w-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-2 px-2 md:px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                      >
                        View Team
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          <div className="flex justify-center mt-20 ">
            <button
              className="bg-indigo-400 px-3 py-2 rounded-[5px] text-white font-[poppins]"
              onClick={handleCreateTeam}
            >
              Create Team
            </button>
          </div>
        </>
      )}
      {view === "createContest" && (
        <>
          {userContest?.created_contests &&
            userContest?.created_contests?.length > 0 && (
              <div className="flex flex-wrap justify-center  gap-4">
                {userContest?.created_contests?.map((contest, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-lg rounded-xl p-4 border flex flex-col justify-center items-center border-gray-200 hover:shadow-2xl transition-shadow duration-300 w-full sm:w-[48%] md:w-[45%] lg:w-[30%] h-auto"
                  >
                    <h2 className="text-xl font-semibold mb-2 text-gray-800">
                      Name : {contest.name}
                    </h2>
                    <p className="text-[14px] font-semibold mb-2 text-gray-800">
                      Contest Code : {contest.contest_code}
                    </p>
                    <p className="text-[14px] font-semibold mb-2 text-gray-800">
                      prize : {contest.prize_pool}
                    </p>
                    <p className="text-[14px] font-semibold mb-2 text-gray-800">
                      Total Spot : {contest.total_spot}
                    </p>
                    <p className="text-[14px] font-semibold mb-2 text-gray-800">
                      Filled spots :{" "}
                      {contest.filled_spot === null ? "0" : contest.filled_spot}
                    </p>

                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => handleSeeMoreAboutContest(contest)}
                        className="mt-6 w-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-2 px-2 md:px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                      >
                        see more
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          {userContest?.created_contests?.length === 0 && (
            <h3 className="text-center">No Created Contests Available Now</h3>
          )}

          <div className="flex justify-center items-center">
            {view === "createContest" && (
              <button
                onClick={handleCreateContest}
                className="mt-6 w-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
              >
                Create User Contest
              </button>
            )}
          </div>
        </>
      )}

      {view === "joined" &&
        (userContest?.joined_contests?.length > 0 ? (
          <div className="flex flex-wrap justify-center gap-2">
            {userContest?.joined_contests?.map((contest, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 hover:shadow-2xl transition-shadow duration-300"
              >
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Contest Code: {contest.contest_code}
                </h2>

                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  Entries: {contest.contest_count}
                </h2>
                <button
                  onClick={() => handleJoinContest(contest.contest_code)}
                  className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
                >
                  View Joined Contest
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">
            <p className="text-lg text-gray-600">No contests joined yet.</p>
          </div>
        ))}

      {view === "privateContest" && (
        <>
          {" "}
          <h2 className="text-2xl font-semibold mb-4 text-blue-500">
            Private Contest
          </h2>
          <div className="shadow-xl rounded-md flex flex-col py-2">
            <div className="mb-4 flex md:flex-row flex-col gap-2 px-4 justify-around py-2 items-center">
              <input
                type="text"
                name="contest_code"
                placeholder="Search Contest Code"
                value={privateFormData.contest_code}
                onChange={handlePrivateDataChange}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                value={privateFormData.password}
                onChange={handlePrivateDataChange}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <button
              onClick={handleSearchPrivateContest}
              className="px-4 py-2 mx-2 text-[16px] rounded-[4px] font-semibold bg-gradient-to-r from-purple-500 to-indigo-500 text-white hover:bg-gradient-to-l hover:from-purple-500 hover:to-indigo-500 duration-300 ease-in-out"
            >
              Search
            </button>
            {/* Render Private Contests Here */}
          </div>
        </>
      )}
    </div>
  );
}

export default JoinUpcomingMatch;
