import React from 'react'

function PlayersStatus({ playerPoints }) {
   console.log(playerPoints)

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead className="bg-blue-100 text-gray-800">
          <tr>
            <th className="py-3 px-4 text-left">Player Code</th>
            <th className="py-3 px-4 text-center">Lineup</th>
            <th className="py-3 px-4 text-center">Point 1</th>
            <th className="py-3 px-4 text-center">Point 2</th>
            <th className="py-3 px-4 text-center">Point 3</th>
            <th className="py-3 px-4 text-center">Point 4</th>
            <th className="py-3 px-4 text-center">Point 5</th>
            <th className="py-3 px-4 text-center">Point 6</th>
            <th className="py-3 px-4 text-center">Point 7</th>
            <th className="py-3 px-4 text-center">Point 8</th>
            <th className="py-3 px-4 text-center">Point 9</th>
            <th className="py-3 px-4 text-center">Point 10</th>
            <th className="py-3 px-4 text-center">Total Points</th>
          </tr>
        </thead>
        <tbody>
          {playerPoints.map((player, index) => (
            <tr key={player.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
              <td className="py-3 px-4 text-left">{player.player_code}</td>
              <td className="py-3 px-4 text-center">{player.lineup}</td>
              <td className="py-3 px-4 text-center">{player.point1}</td>
              <td className="py-3 px-4 text-center">{player.point2}</td>
              <td className="py-3 px-4 text-center">{player.point3}</td>
              <td className="py-3 px-4 text-center">{player.point4}</td>
              <td className="py-3 px-4 text-center">{player.point5}</td>
              <td className="py-3 px-4 text-center">{player.point6}</td>
              <td className="py-3 px-4 text-center">{player.point7}</td>
              <td className="py-3 px-4 text-center">{player.point8}</td>
              <td className="py-3 px-4 text-center">{player.point9}</td>
              <td className="py-3 px-4 text-center">{player.point10}</td>
              <td className="py-3 px-4 text-center font-bold">{player.total_point}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PlayersStatus