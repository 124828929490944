import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

function CreateContest() {
     const {matchId} = useParams()
     const userCode = sessionStorage.getItem("user_code");
     const [errors, setErrors] = useState({condition_link: '',});
    const [isPrivate, setIsPrivate] = useState(false);
    const [formData, setFormData] = useState({
      match_code: matchId,
      name: "",
      prize1: "",
      prize2: "",
      prize3: "",
      prize4: "",
      prize5: "",
      prize_pool: "",
      total_spot: "",
      free_join: "",
      desc: "",
      type: "Public",
      password: "",
      user_code: userCode,
      condition: "",
      condition_link: "",

    });
     const [loading , setLoading] = useState(false)    
    const navigate = useNavigate();
    

    useEffect(()=>{
       if(!userCode && !matchId){
          navigate(-1)
       }         
    },[matchId])

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  
      if (name === "type") {
        setIsPrivate(value === "Private");
      }
      if (name === 'condition' && value.length === 0) {
        setErrors({ condition_link: '' }); // Clear the error if condition is empty
      }
  
      if (name === 'condition_link' && formData.condition.length > 0 && value.length === 0) {
        setErrors({ condition_link: 'Condition link is required if condition is provided.' });
      } else {
        setErrors({ condition_link: '' });
      }
    };

   
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true)
      // console.log(formData);
      if (formData.condition.length > 1 && formData.condition_link.length === 0) {
        setErrors({ condition_link: 'Condition link is required if condition is provided.' });
        return
       }else{
        console.log(formData)
        try {
          const req = await axios.post('https://predictionist.in/api/creating_contest.php ', formData , {
            headers :{
             "Content-Type": "application/json",
            }
          }) 
          if(req.data.success){
             navigate(-1)
          }else{
            alert("please try again")
          }
          setLoading(false);  
        }catch (error) {
          console.log(error.message)
          setLoading(false);  
        }
         
       }
      
    };
  
    
    return (
      <div className="max-w-[90%] font-[poppins] mx-auto p-4 bg-white shadow-2xl rounded-lg ">
        <h2 className="text-2xl  font-bold mb-6 text-center">Create Contest</h2>
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-wrap w-[100%]">
          {/* Match Code */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm  font-medium text-gray-700 md:w-1/3">Match Code</label>
            <input
              type="text"
              name="match_code"
              value={formData.match_code}
              onChange={handleChange}
              required
              readOnly
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            />
          </div>
  
          {/* Contest Name */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Contest Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder='Write your Contest Name'
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            />
          </div>
  
          {/* Prize Inputs */}
          {[1, 2, 3, 4, 5].map((num) => (
            <div key={num} className={`flex flex-col w-full  mb-4 `}>
              <label className={`block text-sm font-medium  md:w-1/3`}>{`Prize ${num} ${num === 1 ? "(required)" : "(Optional)"}`}</label>
              {num === 1 && <p className='flex items-center text-[14px] gap-2'>Note :  <span className='text-[13px]  text-red-500'> You can only create free contest , You can’t ask payment from users</span></p>}
              <input
                type="text"
                name={`prize${num}`}
                value={formData[`prize${num}`]}
                onChange={handleChange}
                required={num[1]}
                placeholder={num === 1 ? "1st Winner Prize (required)" : "Other winners prize (optional)"}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
              />
         
            </div>
          ))}
  
          {/* Prize Pool */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Prize Pool (required)</label>
            <input
              type="text"
              name="prize_pool"
              value={formData.prize_pool}
              onChange={handleChange}
              placeholder='Prize Pool'
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
              required
            />
          </div>
  
          {/* Total Spot */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Total Spot (required)</label>
            <input
              type="text"
              name="total_spot"
              value={formData.total_spot}
              onChange={handleChange}
              placeholder='Maximum teams allowed in this contest'
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            />
          </div>
  
          {/* Free Join Number */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Free Join for User (required)</label>
            <input
              type="text"
              name="free_join"
              value={formData.free_join}
              onChange={handleChange}
              placeholder='Maximum allowed free join for user'
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            />
          </div>
  
          {/* Description */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Description (required)</label>
            <textarea
              name="desc"
              value={formData.desc}
              onChange={handleChange}
              required
              placeholder='Write something about Contest'
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
              rows="4"
            />
          </div>
  
          {/* Type */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Type (required select any one)</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
          </div>
  
          {/* Password (conditional rendering) */}
          {isPrivate && (
            <div className="flex flex-col w-full  mb-4">
              <label className="block text-sm font-medium text-gray-700 md:w-1/3">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
              />
            </div>
          )}
  
          {/* User Code */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">User Code</label>
            <input
              type="text"
              name="user_code"
              value={formData.user_code}
              required
              readOnly
              onChange={handleChange}
              className="mt-1 block w-full border outline-none  rounded-md shadow-sm p-2 "
            />
          </div>
  
          {/* Condition */}
          <div className="flex flex-col w-full  mb-4">
            <label className="block text-sm font-medium text-gray-700 md:w-1/3">Condition (optional)</label>
            <span className='text-[12px] flex flex-col text-green-500'> 
                  <span>1. You can set any condition for users to join your contest.</span>
                  <span>2. Example: Ask users to follow you on Instagram. Provide a link to your Instagram or any task you want them to complete.</span>
                  <span>3.  Conditions can include tasks like Following instagram, Joining whatsapp groups, etc.</span>
               </span>
            <input
              name="condition"
              value={formData.condition}
              onChange={handleChange}
              placeholder='Write Condition for Join your Contest ex. follow me on instagram'
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
              required
            /> 
            <input
              name="condition_link"
              value={formData.condition_link}
              onChange={handleChange}
              placeholder='Please give a link according to Condition'
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
            /> 
               {errors.condition_link && (
                <span className="text-red-500 text-sm">{errors.condition_link}</span>
            )}
          </div>
  
          {/* Submit Button */}
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
            >
              {loading ? "Wait..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    );
}
export default CreateContest