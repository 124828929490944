import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Profile() {
   const location = useLocation();
   const userData = location.state || {}
 
  const navigate = useNavigate()
  
  const handleLogout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user_code");
    navigate("/login");
 };

 console.log(userData)

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-8 py-8">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-sm">
        <div className="flex items-center justify-center mb-4">
          <img
  //  src={`https://predictionist.in/uploads/BKCSEL148903.png`}
          src={`https://predictionist.in${userData.photo}`}
            alt="User Profile"
            className="w-24 h-24 rounded-full object-cover border-4 border-blue-500"
          />
        </div>
        <h2 className="text-xl font-bold text-center mb-4">{userData.name}</h2>
        <div className="space-y-2 mb-6">
          <p className="text-gray-700">
            <strong>User Code:</strong> {userData.user_code}
          </p>
          <p className="text-gray-700">
            <strong>Mobile Number:</strong> {userData.mobile_number}
          </p>
          <p className="text-gray-700">
            <strong>Email:</strong> {userData.mail}
          </p>
          <p className="text-gray-700">
            <strong>Age:</strong> {userData.age}
          </p>
          <p className="text-gray-700">
            <strong>Amount:</strong> ${userData.amount}
          </p>
          <p className="text-gray-700">
            <strong>Free Entry:</strong> {userData.free_entry}
          </p>
          <p className="text-gray-700">
            <strong>Power 1:</strong> {userData.power1}
          </p>
          <p className="text-gray-700">
            <strong>Power 2:</strong> {userData.power2}
          </p>
        </div>

        <button
          onClick={handleLogout}
          className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
        >
          Log Out
        </button>
      </div>
    </div>
  );
}

export default Profile;
