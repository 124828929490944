import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import './App.css';
import VerifyMobile from './Pages/MobileVerify/MobileNumVerify';
import JoinUpcomingMatch from './Pages/JoinUpcomingMatch/JoinUpcomingMatch';
import CreateTeam from './Pages/CreateTeam/CreateTeam';
import EditTeam from './Pages/EditTeam/EditTeam';
import JoinContest from './Pages/JoinContest/JoinContest';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/hero';
import Home from './Components/Hero/Home/home';
import Protected from './ProtectRoute';
import Ranking from './Pages/RankingPage/Ranking';
import { Provider } from 'react-redux';
import store from './Reducers/store';
import ErrorPage from './Error/globalErrorPage';
import PersonalInformation from './Pages/PersonalInformationVerify/PersonalInfo';
import CreateContest from './Pages/CreateContest/CreateContest';
import Wallet from './Pages/WalletPage/Wallet';
import ViewContestDetails from './Pages/CreateContest/ViewMyContestDetails/ViewContestDetails';
import Profile from './Pages/UserPages/ProfilePage/Profile';

function App() {
   
  const PersonalRegisterProtect = ({ children }) => {
    const userEmail = JSON.parse(sessionStorage.getItem("email"));
    return userEmail ? children : <Navigate to="/login" />;
  };
  
    
  return (
    <Provider store={store}>
    <Router>
      <Routes>
        {/* Main layout that includes the Navbar */}
          <Route element={<Protected><Layout /></Protected> }>
          <Route path="/" element={<Protected><Home/></Protected>} />
          <Route path="/matchContest/:matchId" element={<Protected><JoinUpcomingMatch/></Protected>} />
          <Route path="/createTeam/:matchId" element={<Protected><CreateTeam /></Protected>} />
          <Route path="/editTeam/:matchId" element={<Protected><EditTeam /></Protected>} />
          <Route path="/joinedContest/:contestId" element={<Protected><JoinContest /></Protected>} />
          <Route path="/ranking/:userId" element={<Protected><Ranking /></Protected>} />
          <Route path="/createContest/:matchId" element={<Protected><CreateContest /></Protected>} />
          <Route path="/wallet/:userCode" element={<Protected><Wallet /></Protected>} />
          <Route path="/View_Contest/:contestCode" element={<Protected><ViewContestDetails /></Protected>} />
          <Route path="/profile" element={<Protected><Profile /></Protected>} />
          {/* Add other routes here */}
          </Route>
      
        {/* Login route without Navbar */}
        <Route path="/login" element={<VerifyMobile />} />
        <Route path="/personal" element={<PersonalRegisterProtect><PersonalInformation /></PersonalRegisterProtect>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
    </Provider>
  );
}

// Layout component to handle Navbar visibility
function Layout() {
  const location = useLocation();
  const hideNavbar = location.pathname === '/login';

  return (
    <>
      {!hideNavbar && <Navbar />}
    </>
  );
}

export default App;
