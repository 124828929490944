import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://predictionist.in"
export const SendEmail = createAsyncThunk(
  "verify/sendEmail",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/PHPMailer/send_email.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            console.log(req.data)
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const verifyOtp = createAsyncThunk(
  "verify/verifyOtp",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/otpprocess.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
          // console.log(  "otp after is" ,req.data)
          return req.data    
        }else{
          alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const ReSendOtp = createAsyncThunk(
  "verify/reSendOtp",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/PHPMailer/send_email.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);
export const ChangeEmail = createAsyncThunk(
  "verify/changeEmail",
  async (authData, thunkAPI) => {
    try {
        const req = await axios.post(`${API_URL}/api/PHPMailer/send_email.php`, authData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (req.data) {
            return req.data    
        }else{
            alert("please try again")
        }
      } catch (error) {
        console.log("Error in fetching data:", error.message);
        return thunkAPI.rejectWithValue(error.response.data);  
    }   
  }
);


const initialState = {
  data: null,
  loading: false,
  error: null,
};



const Verification = createSlice({
  name: "verify",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(SendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(ReSendOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ReSendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ReSendOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(ChangeEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ChangeEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ChangeEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const authReducer = Verification.reducer 
export const AuthSelector = (state) => state.authReducer;
