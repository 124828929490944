import React from 'react'
import { useLocation } from 'react-router-dom';

function Wallet() {
    const location = useLocation();
    const {data} = location.state;

  return (
        <div className="md:min-h-[80vh] h-[100vh] px-2 flex items-center justify-center bg-gray-100">
          <div className="bg-white shadow-lg rounded-lg p-6 max-w-lg w-full">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Wallet Information</h2>
            <div className="mb-4 md:flex gap-4 items-center">
              <p className="text-gray-600">Mobile Number:</p>
              <p className="text-lg font-semibold text-gray-900">{data.mobile_number}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-gray-600">Amount</p>
                <p className="text-xl font-bold text-gray-800">₹ {data.amount}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-gray-600">Deposit Amount</p>
                <p className="text-xl font-bold text-gray-800">₹ {data.deposit_amount}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-gray-600">Joined Amount</p>
                <p className="text-xl font-bold text-gray-800">₹ {data.joined_amount}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-gray-600">Withdrawal Amount</p>
                <p className="text-xl font-bold text-gray-800">₹ {data.withdrawal_amount}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-gray-600">Won Amount</p>
                <p className="text-xl font-bold text-gray-800">₹ {data.won_amount}</p>
              </div>
            </div>
          </div>
        </div>
      
  );
}

export default Wallet 