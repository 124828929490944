import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MatchRank } from "../../Reducers/TeamContests/Contests";


function JoinContest() {
  const { contestId } = useParams();
  const location = useLocation();
  
  const receivedData = location.state || {};
  const joinedContest = receivedData && receivedData?.joined_contests;
  const userCode = sessionStorage.getItem("user_code") 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmitJoin =async (match)=>{
      const data = {
         user_code: userCode,
         match_code : match?.match_code,
         contest_code : match?.contest_code
      }
    try{
      const req1 = await dispatch(MatchRank(data));
      if(MatchRank.fulfilled.match(req1) && req1?.payload.error){
        alert(req1.payload.error)
      }else if(MatchRank.fulfilled.match(req1) && req1?.payload?.match_details?.update_status === "pending"){
        alert("Match is live now you can see your rank after match finished");
      }else if(MatchRank.fulfilled.match(req1) && req1?.payload?.match_details.update_status !=="pending"){
         alert(  "success ",req1.payload)
         navigate(`/ranking/${contestId}` , {state : req1.payload});         
      }else{
        alert("try again")
      }
      }catch(error) {
          console.log(error.message) 
    }
  }
   
  
  return (
    <div className="p-6 max-w-5xl mx-auto relative font-[poppins]">
      <h2 className="font-bold text-[#6d7883d6] text-[25px] mb-6">
         your Joined Contests
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {joinedContest?.map((contest, i) => {
          return (
            <div
              key={i}
              className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 hover:shadow-2xl transition-shadow duration-300"
            >
              <h2 className="text-xl font-semibold mb-2 text-gray-800">
                Contest Code  : {contest?.contest_code}
              </h2>
              <p>Prize Pool : {contest?.prize_pool}</p>
              <button
                onClick={()=>handleSubmitJoin(contest)}
                className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4 rounded-xl hover:from-purple-600 hover:to-indigo-600 transition-colors duration-300 font-semibold"
              >
                view ranking
              </button>
          

            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JoinContest;
